import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons'
import {Button, message, Popconfirm, Space, Table, Tag, Tooltip} from 'antd'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Grid, Header, Segment} from 'semantic-ui-react'
import {backendUrl} from '../backendUrl'

const Dashboard = () => {
  const [data, setData] = useState([])
  const history = useHistory()

  useEffect(() => {
    axios
      .get(`${backendUrl}/dashboard/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        const users = res.data.map((rec) => ({
          ...rec,
          key: rec.id,
        }))
        setData(users)
      })
  }, [])

  const columns = [
    {
      title: 'Full Name',
      key: 'name',
      dataIndex: 'name',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
    },
    {
      title: 'username',
      key: 'username',
      dataIndex: 'username',
      sorter: (a, b) => (a.username > b.username ? 1 : -1),
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
    },
    {
      title: 'Status',
      key: 'is_active',
      dataIndex: 'is_active',
      render: (text) => {
        let color = ''
        if (text === true) {
          color = 'green'
        } else {
          color = 'red'
        }
        return (
          text !== '' && (
            <Tag color={color} key={text}>
              {text === true ? 'Active' : 'Inactive'}
            </Tag>
          )
        )
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <a onClick={() => history.push('/user/edit/' + record.key)}>
            <Tooltip title='Edit'>
              <EditOutlined className={'action-icon'} />
            </Tooltip>
          </a>
          <Popconfirm
            title='Are you sure delete this user?'
            onConfirm={() => handleDelete(record.key)}
            okText='Yes'
            cancelText='No'>
            <a>
              <Tooltip title='Delete'>
                <DeleteOutlined className={'action-icon'} />
              </Tooltip>
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const handleDelete = (id) => {
    axios
      .delete(`${backendUrl}/user/${id}/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        if (res.status === 204) {
          message.success('Inbound deleted.')
          const newData = data.filter((item) => item.id !== id)
          setData(newData)
        }
      })
  }

  return (
    <Grid textAlign='center' style={{minHeight: '100vh', height: '100%', marginTop: 'unset'}}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>User Management</span>
          </Header>
          <div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px'}}>
            <div style={{width: '700px', display: 'flex', justifyContent: 'flex-end'}}>
              <Button type='primary' onClick={() => history.push('/users/create')} icon={<PlusOutlined />}>
                New User
              </Button>
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Table
              className={'page-table'}
              style={{width: '700px'}}
              size='middle'
              columns={columns}
              dataSource={data}
              pagination={{
                showSizeChanger: true,
                defaultPageSize: 50,
              }}
              showSorterTooltip={false}
            />
          </div>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default Dashboard
