import {DeleteOutlined, EditOutlined, ExportOutlined, PlusOutlined} from '@ant-design/icons'
import {Button, Input, message, Popconfirm, Select, Space, Statistic, Table, Tag, Tooltip} from 'antd'
import axios from 'axios'
import {ExportToCsv} from 'export-to-csv'
import _ from 'lodash'
import moment from 'moment-timezone'
import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {Grid, Header, Segment} from 'semantic-ui-react'
import {backendUrl} from '../backendUrl'
import capitalizeFirstLetter from '../utils/capitalize'

const {Search} = Input
const {Option} = Select

const OutboundList = () => {
  const [data, setData] = useState([])
  const [filterd, setFilterd] = useState([])
  const [org, setOrg] = useState([])
  const [mode, setMode] = useState('Pending')
  const [keyword, setKeyword] = useState('')
  const [totalPallets, setTotalPallets] = useState(0)

  const history = useHistory()

  useEffect(() => {
    axios
      .get(`${backendUrl}/outbounds/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        let apts = res.data.map((rec) => {
          return {
            ...rec,
            key: rec.id,
          }
        })
        setData(apts)
        if (mode !== 'All') {
          apts = apts.filter((rec) => rec.status === mode)
        }
        setFilterd(apts)
        setOrg(res.data)
      })
  }, [])

  useEffect(() => {
    let total = 0
    filterd.map((el) => {
      if (el.pallets && el.status === 'Pending') total += el.pallets
    })
    setTotalPallets(total)
  }, [filterd])

  useEffect(() => filterApt(), [keyword])

  const handleDelete = (id) => {
    axios
      .delete(`${backendUrl}/outbounds/${id}/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        if (res.status === 204) {
          message.success('Outbound deleted.')
          const newData = data.filter((item) => item.id !== id)
          setData(newData)
          const newFilt = filterd.filter((item) => item.id !== id)
          setFilterd(newFilt)
          let newOrg = org.filter((item) => item.id !== id)
          setOrg(newOrg)
        }
      })
  }

  const options = {
    filename: 'outbounds',
    showLabels: true,
    headers: [
      'FBA ID',
      'ARN Number',
      'Pick Done',
      'Pick Up',
      'Customer',
      'Carrier',
      'Number of Pallets',
      'Status',
      'Notes',
    ],
  }

  const exportCSV = () => {
    if (org.length > 0) {
      let csvdata = []
      for (let i = 0; i < org.length; i++) {
        csvdata.push({
          fba_id: org[i]['fba_id'],
          arn_number: org[i]['arn_number'],
          pick_done: org[i]['pick_done'],
          pick_up: org[i]['pick_up'],
          customer: org[i]['customer'],
          carrier: org[i]['carrier'],
          pallets: org[i]['pallets'],
          status: org[i]['status'],
          notes: org[i]['notes'],
        })
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(csvdata)
    } else {
      message.warning('No data to export.')
    }
  }

  const columns = [
    {
      title: 'FBA ID',
      dataIndex: 'fba_id',
      key: 'fba_id',
      sorter: (a, b) => (a.fba_id > b.fba_id ? 1 : -1),
      render: (text, record) => <Link to={`/outbound/view/${record.id}`}>{text}</Link>,
    },
    {
      title: 'ARN Number',
      key: 'arn_number',
      dataIndex: 'arn_number',
      sorter: (a, b) => (a.arn_number > b.arn_number ? 1 : -1),
    },
    {
      title: 'Pick Done',
      dataIndex: 'pick_done',
      key: 'pick_done',
      sorter: (a, b) => (moment(a.pick_done) > moment(b.pick_done) ? 1 : -1),
      render: (text) => {
        text = text && moment(text).format('MMM DD, YYYY')
        return <span>{text}</span>
      },
    },
    {
      title: 'Pick Up',
      dataIndex: 'pick_up',
      key: 'pick_up',
      sorter: (a, b) => (moment(a.pick_up) > moment(b.pick_up) ? 1 : -1),
      render: (text) => {
        text = text && moment(text).format('MMM DD, YYYY')
        return <span>{text}</span>
      },
    },
    {
      title: 'Customer',
      key: 'customer',
      dataIndex: 'customer',
      sorter: (a, b) => (a.customer > b.customer ? 1 : -1),
    },
    {
      title: 'Carrier',
      key: 'carrier',
      dataIndex: 'carrier',
      sorter: (a, b) => (a.carrier > b.carrier ? 1 : -1),
    },
    {
      title: 'Number of Pallets',
      key: 'pallets',
      dataIndex: 'pallets',
      sorter: (a, b) => (a.pallets > b.pallets ? 1 : -1),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        let color = ''
        if (text === 'Pending') {
          color = 'blue'
        } else if (text === 'Shipped') {
          color = 'green'
        } else if (text === 'Canceled') {
          color = 'orange'
        }
        return (
          text !== '' && (
            <Tag color={color} key={text}>
              {capitalizeFirstLetter(text)}
            </Tag>
          )
        )
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <a onClick={() => history.push('/outbound/edit/' + record.key)}>
            <Tooltip title='Edit'>
              <EditOutlined className={'action-icon'} />
            </Tooltip>
          </a>
          <Popconfirm
            title='Are you sure delete this?'
            onConfirm={() => handleDelete(record.key)}
            okText='Yes'
            cancelText='No'>
            <a>
              <Tooltip title='Delete'>
                <DeleteOutlined className={'action-icon'} />
              </Tooltip>
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const filterKeyword = (rec) => {
    const value = keyword.toLowerCase()
    return (
      rec.customer.toLowerCase().indexOf(value) > -1 ||
      rec.fba_id.toLowerCase().indexOf(value) > -1 ||
      rec.arn_number.toLowerCase().indexOf(value) > -1
    )
  }

  const onChangeMode = (value) => {
    // const curTime = moment().tz('America/Los_Angeles').format('Y-MM-DD');
    // const curTime = moment().format('Y-MM-DD');
    let apts
    setMode(value)
    apts = data
    if (value !== 'All') {
      apts = apts.filter((rec) => rec.status === value)
    }
    const newFilt = apts.filter((rec) => {
      return filterKeyword(rec)
    })
    setFilterd(newFilt)
  }

  const filterApt = () => {
    if (data.length > 0) {
      const debouncedSave = _.debounce(() => {
        // const curTime = moment().tz('America/Los_Angeles').format('Y-MM-DD');
        // const curTime = moment().format('Y-MM-DD');
        const newFilt = data.filter((rec) => {
          if (mode === 'All') {
            return filterKeyword(rec)
          } else {
            return rec.status === mode && filterKeyword(rec)
          }
        })
        setFilterd(newFilt)
      }, 1000)
      debouncedSave()
    }
  }

  return (
    <Grid textAlign='center' style={{minHeight: '100vh', height: '100%', marginTop: 'unset'}}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>Outbounds</span>
          </Header>
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Select defaultValue={mode} style={{width: 120}} onChange={onChangeMode}>
                <Option value='All'>All</Option>
                <Option value='Pending'>Pending</Option>
                <Option value='Shipped'>Shipped</Option>
                <Option value='Canceled'>Canceled</Option>
              </Select>
              <Search
                style={{width: 200, marginLeft: '10px'}}
                placeholder='Search by content'
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{display: 'inline-block', marginRight: '50px'}}>
                <Statistic title='Total pending pallets' value={totalPallets} />
              </div>
              <Button style={{marginRight: '10px'}} onClick={exportCSV} icon={<ExportOutlined />}>
                Export CSV
              </Button>
              <Button type='primary' onClick={() => history.push('/outbound/create')} icon={<PlusOutlined />}>
                New Outbound
              </Button>
            </div>
          </div>
          <Table
            className={'page-table'}
            size='middle'
            columns={columns}
            dataSource={filterd}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 50,
            }}
            showSorterTooltip={false}
          />
        </Segment>
      </Grid.Column>
    </Grid>
  )
}
export default OutboundList
