import {Button, Col, Form, Input, message, Radio, Row} from 'antd'
import axios from 'axios'
import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Grid, Header, Segment} from 'semantic-ui-react'
import {backendUrl} from '../backendUrl'

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 6},
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 6,
      offset: 8,
    },
  },
}

const CustCreate = () => {
  const [status, setStatus] = useState('active')
  const [form] = Form.useForm()
  const history = useHistory()

  const onFinish = (values) => {
    const data = {
      name: values['name'],
      operations_email: values['operations_email'],
      billing_email: values['billing_email'],
      manager_email: values['manager_email'],
      external_id: values['external_id'],
      status: status,
    }

    axios
      .post(`${backendUrl}/customers/`, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        form.resetFields()
        message.success('New customer created.')
        history.push('/customers')
      })
      .catch((error) => {
        console.log(error.response)
      })
  }

  return (
    <Grid textAlign='center' style={{minHeight: '100vh', height: '100%', marginTop: 'unset'}}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>New Customer</span>
          </Header>
          <Form
            {...formItemLayout}
            form={form}
            name='cust_create'
            onFinish={onFinish}
            initialValues={{
              status: 'active',
            }}
            scrollToFirstError>
            <Form.Item
              name='name'
              label='Name'
              rules={[
                {
                  required: true,
                  message: 'Please input Name',
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item
              name='operations_email'
              label='Operations Email'
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Please input valid email address',
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item
              name='billing_email'
              label='Billing Email'
              rules={[
                {
                  type: 'email',
                  message: 'Please input valid email address',
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item
              name='manager_email'
              label='Manager Email'
              rules={[
                {
                  type: 'email',
                  message: 'Please input valid email address',
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item
              name='external_id'
              label='3PL ID'
              rules={[
                {
                  len: 4,
                  message: '3PL ID must be exactly 4 characters',
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item name='status' label='Status'>
              <Radio.Group style={{width: '100%'}} onChange={(e) => setStatus(e.target.value)}>
                <Row>
                  <Col span={10} offset={1}>
                    <Radio value={'active'}>Active</Radio>
                  </Col>
                  <Col span={12}>
                    <Radio value={'inactive'}>Inactive</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Row gutter={12}>
                <Col className='gutter-row' span={16}>
                  <Button block type='primary' htmlType='submit'>
                    Create
                  </Button>
                </Col>
                <Col className='gutter-row' span={8}>
                  <Button block type='text' onClick={() => history.push('/customers')}>
                    Back
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default CustCreate
