import {CheckCircleOutlined, DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons'
import {Button, Col, Form, message, Popconfirm, Row, Space, Table, Tag, Tooltip, Typography} from 'antd'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Grid, Header, Segment} from 'semantic-ui-react'
import {backendUrl} from '../backendUrl'

const {Text} = Typography

const mainFormItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 12},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 12},
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 6,
      offset: 8,
    },
  },
}

const FBARemovalView = () => {
  const [data, setData] = useState({})
  const [form] = Form.useForm()
  const history = useHistory()
  const params = useParams()

  useEffect(() => {
    axios
      .get(`${backendUrl}/fba-removals/${params.id}/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data)
          form.resetFields()
        }
      })
  }, [])

  const handleMarkAsComplete = () => {
    const newData = {...data, status: 'Complete'}
    axios
      .put(`${backendUrl}/fba-removals/${newData.id}/`, newData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        message.success('FBA removal marked as complete.')
        setData(newData)
      })
      .catch((error) => {
        console.log(error.response)
      })
  }

  const handleDeleteReceipt = (receiptId) => {
    const newData = {...data, receipts: (data.receipts || []).filter((other) => other.id !== receiptId)}
    axios
      .put(`${backendUrl}/fba-removals/${data.id}/`, newData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        message.success('Receipt deleted.')
        setData(newData)
      })
      .catch((error) => {
        console.log(error.response)
      })
  }

  const getReceivedQuantity = (skuId) => {
    return (data.receipts || [])
      .filter((receipt) => receipt.sku === skuId)
      .map((receipt) => receipt.quantity_received + receipt.quantity_damaged)
      .reduce((q, t) => q + t, 0)
  }

  const skuColumns = [
    {title: 'SKU', dataIndex: 'sku', key: 'sku', sorter: (a, b) => (a.sku > b.sku ? 1 : -1)},
    {title: 'QTY', dataIndex: 'quantity', key: 'quantity', sorter: (a, b) => (a.quantity > b.quantity ? 1 : -1)},
    {title: 'Box Size', dataIndex: 'box_size', key: 'box_size', sorter: (a, b) => (a.box_size > b.box_size ? 1 : -1)},
    {
      title: 'Box Weight',
      dataIndex: 'box_weight',
      key: 'box_weight',
      sorter: (a, b) => (a.box_weight > b.box_weight ? 1 : -1),
      render: (value) => `${value} lbs`,
    },
    {
      title: 'QTY Per Box',
      dataIndex: 'quantity_per_box',
      key: 'quantity_per_box',
      sorter: (a, b) => (a.quantity_per_box > b.quantity_per_box ? 1 : -1),
    },
    {
      title: 'Boxes',
      dataIndex: 'boxes',
      key: 'boxes',
      sorter: (a, b) => (a.boxes > b.boxes ? 1 : -1),
      render: (_, sku) => {
        if (sku.quantity / sku.quantity_per_box !== sku.boxes) {
          return (
            <Tooltip title="Boxes don't add up to SKU quantity and quantity per box">
              <Tag color='gold'>{sku.boxes}</Tag>
            </Tooltip>
          )
        }
        return sku.boxes
      },
    },
    {
      title: 'Received',
      dataIndex: 'receivedQuantity',
      key: 'receivedQuantity',
      render: (_, sku) => {
        if (sku.quantity !== sku.receivedQuantity) {
          return (
            <Tooltip title="Received quantity doesn't add up to SKU quantity">
              <Tag color='gold'>{sku.receivedQuantity}</Tag>
            </Tooltip>
          )
        }
        return <Tag color='green'>{sku.receivedQuantity}</Tag>
      },
    },
  ]

  const receiptColumns = [
    {title: 'SKU', dataIndex: 'sku', key: 'sku', sorter: (a, b) => (a.sku > b.sku ? 1 : -1)},
    {
      title: 'Received Date',
      dataIndex: 'received_date',
      key: 'received_date',
      sorter: (a, b) => (a.received_date > b.received_date ? 1 : -1),
    },
    {title: 'Tracking', dataIndex: 'tracking', key: 'tracking', sorter: (a, b) => (a.tracking > b.tracking ? 1 : -1)},
    {
      title: 'AMZ Barcode',
      dataIndex: 'amz_barcode',
      key: 'amz_barcode',
      sorter: (a, b) => (a.amz_barcode > b.amz_barcode ? 1 : -1),
    },
    {
      title: 'Stated QTY',
      dataIndex: 'stated_quantity',
      key: 'stated_quantity',
      sorter: (a, b) => (a.stated_quantity > b.stated_quantity ? 1 : -1),
    },
    {
      title: 'QTY Received',
      dataIndex: 'quantity_received',
      key: 'quantity_received',
      sorter: (a, b) => (a.quantity_received > b.quantity_received ? 1 : -1),
    },
    {
      title: 'QTY Damaged',
      dataIndex: 'quantity_damaged',
      key: 'quantity_damaged',
      sorter: (a, b) => (a.quantity_damaged > b.quantity_damaged ? 1 : -1),
    },
    {
      title: 'QTY Missing',
      dataIndex: 'quantity_missing',
      key: 'quantity_missing',
      sorter: (a, b) => (a.quantity_missing > b.quantity_missing ? 1 : -1),
    },
    data.status === 'Open'
      ? {
          title: 'Actions',
          key: 'actions',
          render: (_, receipt) =>
            data.status === 'Open' && (
              <Space size='middle'>
                <a onClick={() => history.push(`/fba-removals/edit/${data.id}/receipts/${receipt.key}`)}>
                  <Tooltip title='Edit'>
                    <EditOutlined className='action-icon' />
                  </Tooltip>
                </a>
                <Popconfirm
                  title='Are you sure you want to delete this?'
                  onConfirm={() => handleDeleteReceipt(receipt.key)}
                  okText='Yes'
                  cancelText='No'>
                  <a>
                    <Tooltip title='Delete'>
                      <DeleteOutlined className={'action-icon'} />
                    </Tooltip>
                  </a>
                </Popconfirm>
              </Space>
            ),
        }
      : undefined,
  ].filter(Boolean)

  return (
    <Grid textAlign='center' style={{minHeight: '100vh', height: '100%', marginTop: 'unset'}}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>View FBA removal</span>
          </Header>
          <Form form={form} name='fba_removal_view'>
            <Row gutter={40}>
              <Col span={6}>
                <Form.Item label='Customer' {...mainFormItemLayout}>
                  {data.customer}
                </Form.Item>

                <Form.Item label='Warehouse' {...mainFormItemLayout}>
                  {data.warehouse}
                </Form.Item>

                <Form.Item label='Reference' {...mainFormItemLayout}>
                  {data.reference}
                </Form.Item>

                <Form.Item label='Status' {...mainFormItemLayout}>
                  {data.status}
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {data.status === 'Open' && (
                      <>
                        <Popconfirm
                          title='Are you sure you want to mark this as complete?'
                          onConfirm={handleMarkAsComplete}
                          okText='Yes'
                          cancelText='No'>
                          <Button type='primary' icon={<CheckCircleOutlined />}>
                            Mark as complete
                          </Button>
                        </Popconfirm>
                        <Button
                          style={{marginLeft: '10px'}}
                          type='primary'
                          onClick={() => history.push(`/fba-removals/receipts/create?fba_removal=${data.id}`)}
                          icon={<PlusOutlined />}>
                          New Receipt
                        </Button>
                      </>
                    )}
                  </div>
                </Form.Item>

                <Form.Item>
                  <Table
                    title={() => <Text strong>SKUs</Text>}
                    size='small'
                    bordered
                    pagination={false}
                    columns={skuColumns}
                    dataSource={(data.skus || []).map((sku) => ({
                      ...sku,
                      key: sku.id,
                      receivedQuantity: getReceivedQuantity(sku.id),
                    }))}
                  />
                </Form.Item>

                <Form.Item>
                  <Table
                    title={() => <Text strong>Receipts</Text>}
                    size='small'
                    bordered
                    pagination={false}
                    columns={receiptColumns}
                    dataSource={(data.receipts || []).map((receipt) => ({
                      ...receipt,
                      key: receipt.id,
                      sku: data.skus.find((sku) => sku.id === receipt.sku).sku,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item {...tailFormItemLayout}>
              <Row gutter={12}>
                <Col className='gutter-row' span={8}>
                  <Button block type='primary' onClick={() => history.push('/fba-removals')}>
                    Back
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default FBARemovalView
