import TextField from '@material-ui/core/TextField'
import {Button, Col, Form, Input, message, Radio, Row, Select} from 'antd'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Grid, Header, Segment} from 'semantic-ui-react'
import {backendUrl} from '../backendUrl'

const {TextArea} = Input
const {Option} = Select

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 6},
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 6,
      offset: 8,
    },
  },
}

const AptEdit = () => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState('')
  const [loadType, setLoadType] = useState('')
  const [csize, setCsize] = useState('')
  const [date, setDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [cust, setCust] = useState('')
  const [customers, setCustomers] = useState([])
  const [form] = Form.useForm()
  const history = useHistory()
  const params = useParams()

  useEffect(() => {
    axios
      .get(`${backendUrl}/inbound/${params.id}/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data)
          setDate(res.data['date'])
          setStartTime(res.data['start_time'])
          setEndTime(res.data['end_time'])
          setCust(res.data['customer'])
          setLoading(res.data['loading'])
          setLoadType(res.data['load_type'])
          setCsize(res.data['container_size'])
          form.resetFields()
        }
      })
    axios
      .get(`${backendUrl}/customers/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        setCustomers(res.data)
      })
  }, [])

  const onFinish = (values) => {
    const newData = {
      date: date,
      start_time: startTime,
      end_time: endTime,
      door_number: values['door_number'],
      container: values['container'],
      container_size: csize,
      carrier: values['carrier'],
      customer: cust,
      load: values['load'],
      loading: loading,
      load_type: loadType,
      po: values['po'],
      notes: values['notes'],
    }
    setData(newData)

    axios
      .put(`${backendUrl}/inbound/${params.id}/`, newData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        form.resetFields()
        message.success('Inbound updated.')
        history.push('/inbounds')
      })
  }

  return (
    <Grid textAlign='center' style={{minHeight: '100vh', height: '100%', marginTop: 'unset'}}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>Edit Inbound</span>
          </Header>
          <Form
            {...formItemLayout}
            form={form}
            name='apt_create'
            onFinish={onFinish}
            initialValues={{
              date: data['date'],
              start_time: data['start_time'],
              end_time: data['end_time'],
              door_number: data['door_number'],
              container: data['container'],
              container_size: data['container_size'],
              carrier: data['carrier'],
              customer: data['customer'],
              load: data['load'],
              loading: data['loading'],
              load_type: data['load_type'],
              po: data['po'],
              notes: data['notes'],
            }}
            scrollToFirstError>
            <Form.Item
              name='date'
              label='Date'
              rules={[
                {
                  required: true,
                  message: 'Please select date!',
                },
              ]}>
              <TextField
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setDate(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name='start_time'
              label='Start Time'
              rules={[{required: true, message: 'Please select Time Range!'}]}>
              <TextField
                type='time'
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 900, // 5 min
                }}
                onChange={(e) => setStartTime(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name='end_time'
              label='End Time'
              rules={[{required: true, message: 'Please select Time Range!'}]}>
              <TextField
                type='time'
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </Form.Item>

            <Form.Item name='customer' label='Customer' rules={[{required: true, message: 'Please input Customer'}]}>
              <Select
                showSearch
                style={{width: '100%'}}
                placeholder='Select a customer'
                optionFilterProp='children'
                onChange={(val) => setCust(val)}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {customers.map((cust, index) => (
                  <Option key={index} value={cust.name}>
                    {cust.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name='po' label='PO #'>
              <Input />
            </Form.Item>

            <Form.Item name='carrier' label='Carrier' rules={[{required: true, message: 'Please input Carrier!'}]}>
              <Input />
            </Form.Item>

            <Form.Item
              name='container'
              label='Container Number'
              rules={[{required: true, message: 'Please input Container!'}]}>
              <Input />
            </Form.Item>

            <Form.Item name='container_size' label='Container Size'>
              <Select style={{width: '100%'}} onChange={(val) => setCsize(val)} allowClear>
                <Option key='LTL'>LTL</Option>
                <Option key='20ft'>20 ft</Option>
                <Option key='40ft'>40 ft</Option>
                <Option key='53ft'>53 ft</Option>
              </Select>
            </Form.Item>

            <Form.Item name='load' label='Load #'>
              <Input />
            </Form.Item>

            <Form.Item name='loading' label='Loading'>
              <Radio.Group style={{width: '100%'}} onChange={(e) => setLoading(e.target.value)}>
                <Row>
                  <Col span={10} offset={1}>
                    <Radio value={'live_unload'}>Live Unload</Radio>
                  </Col>
                  <Col span={12}>
                    <Radio value={'drop_off'}>Drop Off</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>

            <Form.Item name='load_type' label='Load Type'>
              <Radio.Group style={{width: '100%'}} onChange={(e) => setLoadType(e.target.value)}>
                <Row>
                  <Col span={10} offset={1}>
                    <Radio value={'floor_loaded'}>Floor Loaded</Radio>
                  </Col>
                  <Col span={12}>
                    <Radio value={'palletized'}>Palletized</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>

            <Form.Item name='door_number' label='Door Number'>
              <Input />
            </Form.Item>

            <Form.Item name='notes' label='Notes'>
              <TextArea rows={3} />
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Row gutter={12}>
                <Col className='gutter-row' span={16}>
                  <Button block type='primary' htmlType='submit'>
                    Update
                  </Button>
                </Col>
                <Col className='gutter-row' span={8}>
                  <Button block type='text' onClick={() => history.push('/inbounds')}>
                    Back
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default AptEdit
