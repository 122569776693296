import {CheckCircleOutlined, DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons'
import {Button, Input, message, Popconfirm, Select, Space, Table, Tooltip} from 'antd'
import axios from 'axios'
import React, {useEffect, useMemo, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {Grid, Header, Segment} from 'semantic-ui-react'
import {backendUrl} from '../backendUrl'

const {Search} = Input
const {Option} = Select

const FBARemovals = () => {
  const [data, setData] = useState([])
  const [mode, setMode] = useState('Open')
  const [keyword, setKeyword] = useState('')
  const history = useHistory()

  useEffect(() => {
    axios
      .get(`${backendUrl}/fba-removals/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        const removals = res.data.map((rec) => {
          return {
            ...rec,
            key: rec.id,
          }
        })
        setData(removals)
      })
  }, [])

  const handleMarkAsComplete = (record) => {
    record.status = 'Complete'
    axios
      .put(`${backendUrl}/fba-removals/${record.id}/`, record, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        message.success('FBA removal marked as complete.')
        setData(data.map((fbaRemoval) => (fbaRemoval.id !== record.id ? fbaRemoval : record)))
      })
      .catch((error) => {
        console.log(error.response)
      })
  }

  const handleDelete = (id) => {
    axios
      .delete(`${backendUrl}/fba-removals/${id}/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        if (res.status === 204) {
          message.success('FBA removal deleted.')
          const newData = data.filter((item) => item.id !== id)
          setData(newData)
        }
      })
  }

  const columns = [
    {
      title: 'Reference',
      dataIndex: 'reference',
      key: 'reference',
      render: (text, record) => <Link to={`/fba-removals/view/${record.id}`}>{text}</Link>,
      sorter: (a, b) => (a.reference > b.reference ? 1 : -1),
    },
    {
      title: 'Customer',
      key: 'customer',
      dataIndex: 'customer',
      sorter: (a, b) => (a.customer > b.customer ? 1 : -1),
    },
    {
      title: 'Warehouse',
      key: 'warehouse',
      dataIndex: 'warehouse',
      sorter: (a, b) => (a.warehouse > b.warehouse ? 1 : -1),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: (a, b) => (a.status > b.status ? 1 : -1),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) =>
        record.status === 'Open' && (
          <Space size='middle'>
            <Popconfirm
              title='Are you sure you want to mark this as complete?'
              onConfirm={() => handleMarkAsComplete(record)}
              okText='Yes'
              cancelText='No'>
              <a>
                <Tooltip title='Mark as complete'>
                  <CheckCircleOutlined className='action-icon' />
                </Tooltip>
              </a>
            </Popconfirm>
            <a onClick={() => history.push('/fba-removals/edit/' + record.key)}>
              <Tooltip title='Edit'>
                <EditOutlined className={'action-icon'} />
              </Tooltip>
            </a>
            <Popconfirm
              title='Are you sure delete this?'
              onConfirm={() => handleDelete(record.key)}
              okText='Yes'
              cancelText='No'>
              <a>
                <Tooltip title='Delete'>
                  <DeleteOutlined className={'action-icon'} />
                </Tooltip>
              </a>
            </Popconfirm>
          </Space>
        ),
    },
  ]

  const filtered = useMemo(() => {
    return data.filter((rec) => {
      let show = true
      if (mode !== 'All') {
        show = show && rec.status === mode
      }
      if (keyword) {
        show =
          show &&
          (rec.reference.toLowerCase().includes(keyword) ||
            rec.customer.toLowerCase().includes(keyword) ||
            rec.warehouse.toLowerCase().includes(keyword))
      }
      return show
    })
  }, [data, mode, keyword])

  return (
    <Grid textAlign='center' style={{minHeight: '100vh', height: '100%', marginTop: 'unset'}}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>FBA removals</span>
          </Header>
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
            <div>
              <Select defaultValue={mode} style={{width: 120}} onChange={setMode}>
                <Option value='Open'>Open</Option>
                <Option value='Complete'>Complete</Option>
                <Option value='Canceled'>Canceled</Option>
                <Option value='All'>All</Option>
              </Select>
              <Search
                style={{width: 200, marginLeft: '10px'}}
                placeholder='Search by content'
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
            <div>
              <Button
                type='primary'
                style={{marginRight: '10px'}}
                onClick={() => history.push('/fba-removals/create')}
                icon={<PlusOutlined />}>
                New FBA removal
              </Button>
              <Button
                type='primary'
                onClick={() => history.push('/fba-removals/receipts/create')}
                icon={<PlusOutlined />}>
                New Receipt
              </Button>
            </div>
          </div>
          <Table
            className={'page-table'}
            size='middle'
            columns={columns}
            dataSource={filtered}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 50,
            }}
            showSorterTooltip={false}
          />
        </Segment>
      </Grid.Column>
    </Grid>
  )
}
export default FBARemovals
