import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons'
import {Button, Input, message, Popconfirm, Select, Space, Table, Tag, Tooltip} from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {Grid, Header, Segment} from 'semantic-ui-react'
import {backendUrl} from '../backendUrl'

const {Search} = Input
const {Option} = Select

const CustomerPage = () => {
  const [customers, setCustomers] = useState([])
  const [filterd, setFilterd] = useState([])
  const [keyword, setKeyword] = useState('')
  const [mode, setMode] = useState('active')
  const history = useHistory()

  useEffect(() => {
    axios
      .get(`${backendUrl}/customers/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        const data = res.data.map((rec) => {
          return {
            ...rec,
            key: rec.id,
          }
        })
        setCustomers(data)
        const filt = data.filter((rec) => rec.status === 'active')
        setFilterd(filt)
      })
  }, [])

  useEffect(() => filterApt(), [keyword])

  const handleDelete = (id) => {
    axios
      .delete(`${backendUrl}/customer/${id}/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        if (res.status === 204) {
          message.success('Customer deleted.')
          const newCust = customers.filter((item) => item.id !== id)
          setCustomers(newCust)
          const newFilt = filterd.filter((item) => item.id !== id)
          setFilterd(newFilt)
        }
      })
  }

  const filterKeyword = (rec) => {
    const value = keyword.toLowerCase()
    return rec.name.toLowerCase().indexOf(value) > -1
  }

  const onChangeMode = (value) => {
    // const curTime = moment().tz('America/Los_Angeles').format('Y-MM-DD');
    // const curTime = moment().format('Y-MM-DD');
    let cust
    setMode(value)
    cust = customers
    if (value !== 'All') {
      cust = cust.filter((rec) => rec.status === value)
    }
    const newFilt = cust.filter((rec) => {
      return filterKeyword(rec)
    })
    setFilterd(newFilt)
  }

  const filterApt = () => {
    if (customers.length > 0) {
      const debouncedSave = _.debounce(() => {
        // const curTime = moment().tz('America/Los_Angeles').format('Y-MM-DD');
        // const curTime = moment().format('Y-MM-DD');
        const newFilt = customers.filter((rec) => {
          if (mode === 'All') {
            return filterKeyword(rec)
          } else {
            return rec.status === mode && filterKeyword(rec)
          }
        })
        setFilterd(newFilt)
      }, 1000)
      debouncedSave()
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <Link to={`/customer/view/${record.id}`}>{text}</Link>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      render: (text) => (
        <Tag color={text === 'active' ? 'green' : 'volcano'} key={text}>
          {text === 'active' ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '20%',
      render: (text, record) => (
        <Space size='middle'>
          <a onClick={() => history.push('/customer/edit/' + record.key)}>
            <Tooltip title='Edit'>
              <EditOutlined className={'action-icon'} />
            </Tooltip>
          </a>
          {record.is_removable && (
            <Popconfirm
              title='Are you sure delete this customer?'
              onConfirm={() => handleDelete(record.key)}
              okText='Yes'
              cancelText='No'>
              <a>
                <Tooltip title='Delete'>
                  <DeleteOutlined className={'action-icon'} />
                </Tooltip>
              </a>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ]

  return (
    <Grid textAlign='center' style={{minHeight: '100vh', height: '100%', marginTop: 'unset'}}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>Customers</span>
          </Header>
          <div style={{width: '700px', display: 'flex', justifyContent: 'space-between', margin: '0 auto 10px'}}>
            <div>
              <Select defaultValue={mode} style={{width: 120}} onChange={onChangeMode}>
                <Option value='active'>Active</Option>
                <Option value='inactive'>Inactive</Option>
                <Option value='All'>All</Option>
              </Select>
              <Search
                style={{width: 200, marginLeft: '10px'}}
                placeholder='Search by content'
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button type='primary' onClick={() => history.push('/customer/create')} icon={<PlusOutlined />}>
                New Customer
              </Button>
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Table
              className={'page-table'}
              style={{width: '700px'}}
              size='small'
              columns={columns}
              dataSource={filterd}
              pagination={false}
            />
          </div>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default CustomerPage
