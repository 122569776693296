import {DownloadOutlined} from '@ant-design/icons'
import {Button, DatePicker, Form, Select} from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {backendUrl} from '../../backendUrl'

const {Option} = Select
const {RangePicker} = DatePicker

const FBARemovalsReport = () => {
  const [loading, setLoading] = useState(false)
  const [customerOptions, setCustomerOptions] = useState([])

  useEffect(() => {
    axios
      .get(`${backendUrl}/customers/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        setCustomerOptions([
          {value: '', label: 'All'},
          ...res.data.map((customer) => ({value: customer.name, label: customer.name})),
        ])
      })
  }, [])

  const onFinish = (values) => {
    setLoading(true)
    axios
      .get(`${backendUrl}/fba-removals/report-by-sku/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'text/csv'},
        params: {
          ...values,
          date_range: values.date_range.map((d) => d.toISOString()),
        },
      })
      .then((res) => {
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = encodeURI(`data:text/csv;charset=utf-8,${res.data}`)
        a.download = `FBARemovalSKUs-${new Date().toISOString()}.csv`
        document.body.appendChild(a)
        a.click()
        a.remove()
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Form
      layout='inline'
      initialValues={{date_range: [moment().startOf('month'), moment().endOf('month')], status: '', customer: ''}}
      onFinish={onFinish}
      autoComplete='off'>
      <Form.Item label='Date range' name='date_range' rules={[{required: true}]}>
        <RangePicker
          ranges={{
            'Current Month': [moment().startOf('month'), moment().endOf('month')],
            'Previous Month': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
          }}
          disabled={loading}
        />
      </Form.Item>

      <Form.Item label='Status' name='status'>
        <Select style={{width: '120px'}} disabled={loading}>
          <Option key=''>All</Option>
          <Option key='Open'>Open</Option>
          <Option key='Complete'>Complete</Option>
          <Option key='Canceled'>Canceled</Option>
        </Select>
      </Form.Item>

      <Form.Item name='customer' label='Customer'>
        <Select showSearch style={{width: '200px'}} placeholder='Select a customer' options={customerOptions} />
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit' loading={loading} icon={<DownloadOutlined />}>
          {loading ? 'Generating report...' : 'Download report'}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default FBARemovalsReport
