import TextField from '@material-ui/core/TextField'
import {Alert, Button, Col, Form, Input, InputNumber, message, Row, Select} from 'antd'
import axios from 'axios'
import React, {useEffect, useMemo, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Grid, Header, Segment} from 'semantic-ui-react'
import {backendUrl} from '../backendUrl'

const mainFormItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 6},
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 6,
      offset: 8,
    },
  },
}

const FBARemovalReceiptEdit = () => {
  const [data, setData] = useState({})
  const [form] = Form.useForm()
  const history = useHistory()
  const params = useParams()
  const [invalidQuantities, setInvalidQuantities] = useState(false)

  useEffect(() => {
    axios
      .get(`${backendUrl}/fba-removals/${params.fbaRemovalId}/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data)
          form.resetFields()
        }
      })
  }, [])

  const receiptId = parseInt(params.id)

  const receipt = useMemo(() => {
    return (data.receipts || []).find((receipt) => receipt.id === receiptId)
  }, [data, params])

  const onFinish = (newReceipt) => {
    const newData = {
      ...data,
      receipts: data.receipts.map((oldReceipt) =>
        oldReceipt.id !== receiptId ? oldReceipt : {...oldReceipt, ...newReceipt},
      ),
    }
    axios
      .put(`${backendUrl}/fba-removals/${data.id}/`, newData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        message.success('FBA removal receipt updated.')
        history.push(`/fba-removals/view/${data.id}`)
      })
      .catch((error) => {
        console.log(error.response)
      })
  }

  const handleValuesChange = (changedValues, allValues) => {
    setInvalidQuantities(
      allValues.stated_quantity !==
        allValues.quantity_received + allValues.quantity_damaged + allValues.quantity_missing,
    )
  }

  return (
    <Grid textAlign='center' style={{minHeight: '100vh', height: '100%', marginTop: 'unset'}}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>Edit FBA removal receipt</span>
          </Header>
          <Form
            form={form}
            name='fba_removal_receipt_edit'
            initialValues={receipt}
            onFinish={onFinish}
            onValuesChange={handleValuesChange}
            scrollToFirstError>
            <Form.Item label='Customer' {...mainFormItemLayout}>
              {data.customer}
            </Form.Item>

            <Form.Item label='Warehouse' {...mainFormItemLayout}>
              {data.warehouse}
            </Form.Item>

            <Form.Item label='Reference' {...mainFormItemLayout}>
              {data.reference}
            </Form.Item>

            <Form.Item name='sku' label='SKU' rules={[{required: true}]} {...mainFormItemLayout}>
              <Select
                showSearch
                style={{width: '100%'}}
                placeholder='Select a SKU'
                options={(data.skus || []).map((sku) => ({value: sku.id, label: sku.sku}))}
              />
            </Form.Item>

            <Form.Item name='received_date' label='Received Date' rules={[{required: true}]} {...mainFormItemLayout}>
              <TextField
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Form.Item>

            <Form.Item name='tracking' label='Tracking' rules={[{required: true}]} {...mainFormItemLayout}>
              <Input />
            </Form.Item>

            <Form.Item name='amz_barcode' label='AMZ Barcode' rules={[{required: true}]} {...mainFormItemLayout}>
              <Input />
            </Form.Item>

            <Form.Item
              name='stated_quantity'
              label='Stated QTY'
              rules={[{required: true, type: 'integer', min: 0}]}
              {...mainFormItemLayout}>
              <InputNumber />
            </Form.Item>

            <Form.Item
              name='quantity_received'
              label='QTY Received'
              rules={[{required: true, type: 'integer', min: 0}]}
              {...mainFormItemLayout}>
              <InputNumber />
            </Form.Item>

            <Form.Item
              name='quantity_damaged'
              label='QTY Damaged'
              rules={[{required: true, type: 'integer', min: 0}]}
              {...mainFormItemLayout}>
              <InputNumber />
            </Form.Item>

            <Form.Item
              name='quantity_missing'
              label='QTY Missing'
              rules={[{required: true, type: 'integer', min: 0}]}
              {...mainFormItemLayout}>
              <InputNumber />
            </Form.Item>

            {invalidQuantities && (
              <Form.Item {...tailFormItemLayout}>
                <Alert type='warning' message="Please review quantities because they don't add up." />
              </Form.Item>
            )}

            <Form.Item {...tailFormItemLayout}>
              <Row gutter={12}>
                <Col className='gutter-row' span={16}>
                  <Button block type='primary' htmlType='submit'>
                    Update
                  </Button>
                </Col>
                <Col className='gutter-row' span={8}>
                  <Button block type='text' onClick={() => history.push(`/fba-removals/view/${data.id}`)}>
                    Back
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default FBARemovalReceiptEdit
