import {UploadOutlined} from '@ant-design/icons'
import TextField from '@material-ui/core/TextField'
import {Button, Col, Form, Input, message, Row, Select, Upload} from 'antd'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Grid, Header, Segment} from 'semantic-ui-react'
import {backendUrl} from '../backendUrl'

const {TextArea} = Input
const {Option} = Select

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 6},
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 6,
      offset: 8,
    },
  },
}

const OutboundEdit = () => {
  const [data, setData] = useState({})
  const [status, setStatus] = useState('')
  const [pickDone, setPickDone] = useState('')
  const [pickUp, setPickUp] = useState('')
  const [cust, setCust] = useState('')
  const [customers, setCustomers] = useState([])
  const [deliveryName, setDeliveryName] = useState('')
  const [delivery, setDelivery] = useState(null)
  const [fileList, setFileList] = useState([])
  const [uploading, setUploading] = useState(false)
  const [form] = Form.useForm()
  const history = useHistory()
  const params = useParams()

  useEffect(() => {
    axios
      .get(`${backendUrl}/outbounds/${params.id}/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data)
          setPickDone(res.data['pick_done'])
          setPickUp(res.data['pick_up'])
          setCust(res.data['customer'])
          setStatus(res.data['status'])
          if (res.data['delivery']) setDelivery(res.data['delivery'])
          if (res.data['delivery_name']) setDeliveryName(res.data['delivery_name'])
          form.resetFields()
        }
      })
    axios
      .get(`${backendUrl}/customers/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        setCustomers(res.data)
      })
  }, [])

  const onFinish = (values) => {
    const newData = {
      fba_id: values['fba_id'],
      arn_number: values['arn_number'] || '',
      pick_done: pickDone || '',
      pick_up: pickUp || '',
      customer: cust,
      carrier: values['carrier'] || '',
      pallets: values['pallets'] || '',
      status: status,
      notes: values['notes'] || '',
    }
    const formData = new FormData()
    formData.append('fba_id', newData['fba_id'])
    formData.append('arn_number', newData['arn_number'])
    formData.append('pick_done', newData['pick_done'])
    formData.append('pick_up', newData['pick_up'])
    formData.append('customer', newData['customer'])
    formData.append('carrier', newData['carrier'])
    formData.append('pallets', newData['pallets'])
    formData.append('status', newData['status'])
    formData.append('notes', newData['notes'])
    console.log(fileList)
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append('delivery_name', file.name)
        formData.append('delivery', file)
      })
    }

    setUploading(true)

    axios
      .put(`${backendUrl}/outbounds/${params.id}/`, formData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        form.resetFields()
        message.success('Outbound updated.')
        history.push('/outbounds')
        setUploading(false)
      })
  }

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])
      return false
    },
  }
  if (data['delivery'] && data['delivery_name']) {
    props['defaultFileList'] = [
      {
        uid: '1',
        name: data['delivery_name'],
        status: 'done',
        url: data['delivery'],
      },
    ]
  }

  return (
    <Grid textAlign='center' style={{minHeight: '100vh', height: '100%', marginTop: 'unset'}}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>Edit Outbound</span>
          </Header>
          <Form
            {...formItemLayout}
            form={form}
            name='apt_create'
            onFinish={onFinish}
            initialValues={{
              fba_id: data['fba_id'],
              arn_number: data['arn_number'],
              pick_done: data['pick_done'],
              pick_up: data['pick_up'],
              carrier: data['carrier'],
              customer: data['customer'],
              pallets: data['pallets'],
              status: data['status'],
              notes: data['notes'],
            }}
            scrollToFirstError>
            <Form.Item
              name='fba_id'
              label='FBA ID'
              rules={[
                {
                  required: true,
                  message: 'Please input FBA ID!',
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item name='arn_number' label='ARN Number'>
              <Input />
            </Form.Item>

            <Form.Item name='pick_done' label='Pick Done'>
              <TextField
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setPickDone(e.target.value)}
              />
            </Form.Item>

            <Form.Item name='pick_up' label='Pick Up'>
              <TextField
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setPickUp(e.target.value)}
              />
            </Form.Item>

            <Form.Item name='customer' label='Customer' rules={[{required: true, message: 'Please input Customer'}]}>
              <Select
                showSearch
                style={{width: '100%'}}
                placeholder='Select a customer'
                optionFilterProp='children'
                onChange={(val) => setCust(val)}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {customers.map((cust, index) => (
                  <Option key={index} value={cust.name}>
                    {cust.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name='carrier' label='Carrier'>
              <Input />
            </Form.Item>

            <Form.Item
              name='pallets'
              label='Number of pallets'
              rules={[
                {
                  validator: async (_, pallets) => {
                    if (pallets && isNaN(pallets)) {
                      return Promise.reject(new Error('Should be a valid integer'))
                    }
                  },
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item name='status' label='Status'>
              <Select style={{width: '100%'}} onChange={(val) => setStatus(val)} allowClear>
                <Option key='Pending'>Pending</Option>
                <Option key='Shipped'>Shipped</Option>
                <Option key='Canceled'>Canceled</Option>
              </Select>
            </Form.Item>

            <Form.Item name='notes' label='Notes'>
              <TextArea rows={3} />
            </Form.Item>

            <Form.Item name='delivery' label='Proof of Delivery'>
              <Upload {...props}>
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Row gutter={12}>
                <Col className='gutter-row' span={16}>
                  <Button block type='primary' htmlType='submit' loading={uploading}>
                    Update
                  </Button>
                </Col>
                <Col className='gutter-row' span={8}>
                  <Button block type='text' onClick={() => history.push('/outbounds')}>
                    Back
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default OutboundEdit
