import React, {useState} from 'react'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {Button, Form, Grid, Header, Segment} from 'semantic-ui-react'
import {authLogin} from '../store/actions/auth'

const LoginForm = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()

  const handleChange = (e) => {
    if (e.target.name === 'email') setEmail(e.target.value)
    if (e.target.name === 'password') setPassword(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    props.login(email, password)
  }

  const handleForgot = (e) => {
    e.preventDefault()
    history.push('/reset-password')
  }

  // const responseGoogle = (response) => {
  //   if (response.tokenId) {
  //     fetch(`${url}/auth/google/`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json"
  //       },
  //       body: JSON.stringify({
  //         user: {
  //           tokenId: response.tokenId
  //         }
  //       })
  //     }).then(res => res.json())
  //       .then(json => {
  //         if (json.non_field_errors) {
  //           notify(json.non_field_errors[0]);
  //         } else {
  //           props.authenticateAction(
  //             json,
  //             props.dispatch,
  //             props.location.pathname,
  //             props.history.push
  //           );
  //         }
  //       });
  //   }
  // }

  const {error, loading} = props
  return (
    <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
      <Grid.Column style={{maxWidth: 450}}>
        <Header as='h2' color='blue' textAlign='center'>
          Log-in to your account
        </Header>

        <React.Fragment>
          <Form size='large' onSubmit={handleSubmit}>
            <Segment stacked>
              <Form.Input
                onChange={handleChange}
                value={email}
                name='email'
                fluid
                icon='user'
                iconPosition='left'
                placeholder='Username'
              />
              <Form.Input
                onChange={handleChange}
                fluid
                value={password}
                name='password'
                icon='lock'
                iconPosition='left'
                placeholder='Password'
                type='password'
              />
              {error && (
                <p style={{color: 'red'}}>
                  {error.non_field_errors ? error.non_field_errors[0] : 'Please input your username and password'}
                </p>
              )}
              <Button color='blue' fluid size='large' loading={loading} disabled={loading}>
                Login
              </Button>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '15px', fontSize: '12px'}}>
                <a href='/reset-password' onClick={handleForgot}>
                  Forgot Password?
                </a>
              </div>
            </Segment>
          </Form>
        </React.Fragment>
      </Grid.Column>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(authLogin(email, password)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
