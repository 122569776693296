import {Button, Col, Form, Row} from 'antd'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Grid, Header, Segment} from 'semantic-ui-react'
import {backendUrl} from '../backendUrl'

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 6},
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 6,
      offset: 8,
    },
  },
}

const CustEdit = () => {
  const [data, setData] = useState({})
  const [status, setStatus] = useState('active')
  const [form] = Form.useForm()
  const history = useHistory()
  const params = useParams()

  useEffect(() => {
    axios
      .get(`${backendUrl}/customer/${params.id}/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data)
          setStatus(res.data['status'])
          form.resetFields()
        }
      })
  }, [])

  return (
    <Grid textAlign='center' style={{minHeight: '100vh', height: '100%', marginTop: 'unset'}}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>View Customer</span>
          </Header>
          <Form {...formItemLayout} form={form} name='cust_view' scrollToFirstError>
            <Form.Item name='name' label='Name'>
              {data['name']}
            </Form.Item>

            <Form.Item name='username' label='Username'>
              {data['user'] && data['user']['username']}
            </Form.Item>

            <Form.Item name='operations_email' label='Operations Email'>
              {data['operations_email']}
            </Form.Item>

            <Form.Item name='billing_email' label='Billing Email'>
              {data['billing_email']}
            </Form.Item>

            <Form.Item name='manager_email' label='Manager Email'>
              {data['manager_email']}
            </Form.Item>

            <Form.Item name='status' label='Status'>
              {data['status']}
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Row gutter={12}>
                <Col className='gutter-row' span={8}>
                  <Button block type='primary' onClick={() => history.push('/customers')}>
                    Back
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default CustEdit
