import {Button, Col, Form, Row} from 'antd'
import axios from 'axios'
import moment from 'moment-timezone'
import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Grid, Header, Segment} from 'semantic-ui-react'
import {backendUrl} from '../backendUrl'

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 6},
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 6,
      offset: 8,
    },
  },
}

const AptEdit = () => {
  const [data, setData] = useState({})
  const [form] = Form.useForm()
  const history = useHistory()
  const params = useParams()

  useEffect(() => {
    axios
      .get(`${backendUrl}/inbound/${params.id}/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data)
          form.resetFields()
        }
      })
  }, [])

  return (
    <Grid textAlign='center' style={{minHeight: '100vh', height: '100%', marginTop: 'unset'}}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>Edit Inbound</span>
          </Header>
          <Form {...formItemLayout} form={form} name='apt_create' scrollToFirstError>
            <Form.Item name='date' label='Date'>
              {moment(data['date']).format('MM/DD/YYYY')}
            </Form.Item>

            <Form.Item name='start_time' label='Start Time'>
              {moment(data['start_time'], 'HH:mm').format('h:mm A')}
            </Form.Item>

            <Form.Item name='end_time' label='End Time'>
              {moment(data['end_time'], 'HH:mm').format('h:mm A')}
            </Form.Item>

            <Form.Item name='customer' label='Customer'>
              {data['customer']}
            </Form.Item>

            <Form.Item name='po' label='PO #'>
              {data['po']}
            </Form.Item>

            <Form.Item name='carrier' label='Carrier'>
              {data['carrier']}
            </Form.Item>

            <Form.Item name='container' label='Container Number'>
              {data['container']}
            </Form.Item>

            <Form.Item name='container_size' label='Container Size'>
              {data['container_size']}
            </Form.Item>

            <Form.Item name='load' label='Load #'>
              {data['load']}
            </Form.Item>

            <Form.Item name='loading' label='Loading'>
              {data['loading']}
            </Form.Item>

            <Form.Item name='load_type' label='Load Type'>
              {data['load_type']}
            </Form.Item>

            <Form.Item name='door_number' label='Door Number'>
              {data['door_number']}
            </Form.Item>

            <Form.Item name='notes' label='Notes'>
              {data['notes']}
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Row gutter={12}>
                <Col className='gutter-row' span={8}>
                  <Button block type='primary' onClick={() => history.push('/inbounds')}>
                    Back
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default AptEdit
