import axios from 'axios'
import {backendUrl} from '../../backendUrl'
import * as actionTypes from './actionTypes'

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  }
}

export const authSuccess = (token, role) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    role: role,
  }
}

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  }
}

export const logout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('expirationDate')
  localStorage.removeItem('role')
  return {
    type: actionTypes.AUTH_LOGOUT,
  }
}

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout())
    }, expirationTime * 1000)
  }
}

export const authLogin = (username, password) => {
  return (dispatch) => {
    dispatch(authStart())
    axios
      .post(`${backendUrl}/auth/login/`, {
        user: {
          username: username,
          password: password,
        },
      })
      .then((res) => {
        const token = res.data.token
        const role = res.data.role
        const expirationDate = new Date(new Date().getTime() + 3600 * 1000)
        localStorage.setItem('token', token)
        localStorage.setItem('expirationDate', expirationDate)
        localStorage.setItem('role', role)
        dispatch(authSuccess(token, role))
        dispatch(checkAuthTimeout(3600))
      })
      .catch((err) => {
        dispatch(authFail(err.response.data))
      })
  }
}

export const authSignup = (username, email, password1, password2) => {
  return (dispatch) => {
    dispatch(authStart())
    axios
      .post('http://127.0.0.1:8000/auth/registration/', {
        username: username,
        email: email,
        password1: password1,
        password2: password2,
      })
      .then((res) => {
        const token = res.data.key
        const expirationDate = new Date(new Date().getTime() + 3600 * 1000)
        localStorage.setItem('token', token)
        localStorage.setItem('expirationDate', expirationDate)
        dispatch(authSuccess(token))
        dispatch(checkAuthTimeout(3600))
      })
      .catch((err) => {
        dispatch(authFail(err))
      })
  }
}

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem('token')
    const role = localStorage.getItem('role')
    if (token === undefined) {
      dispatch(logout())
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'))
      if (expirationDate <= new Date()) {
        dispatch(logout())
      } else {
        dispatch(authSuccess(token, role))
        dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000))
      }
    }
  }
}
