import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons'
import {Alert, Button, Col, Divider, Form, Input, InputNumber, message, Row, Select, Space} from 'antd'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Grid, Header, Segment} from 'semantic-ui-react'
import {backendUrl} from '../backendUrl'
import {boxSizes, warehouses} from '../utils/enums'

const {Option} = Select

const mainFormItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 12},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 12},
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 6,
      offset: 8,
    },
  },
}

const FBARemovalEdit = () => {
  const [data, setData] = useState({})
  const [customers, setCustomers] = useState([])
  const [invalidReceipts, setInvalidReceipts] = useState(false)
  const [form] = Form.useForm()
  const history = useHistory()
  const params = useParams()

  useEffect(() => {
    axios
      .get(`${backendUrl}/fba-removals/${params.id}/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data)
          form.resetFields()
        }
      })
  }, [])

  useEffect(() => {
    axios
      .get(`${backendUrl}/customers/`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        setCustomers(res.data)
      })
  }, [])

  const onFinish = (values) => {
    const requestData = {
      ...values,
      receipts: data.receipts.filter((r) => values.skus.map((sku) => sku.id).includes(r.sku)),
    }

    axios
      .put(`${backendUrl}/fba-removals/${params.id}/`, requestData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      })
      .then((res) => {
        form.resetFields()
        message.success('FBA removal updated.')
        history.push('/fba-removals')
      })
      .catch((error) => {
        console.log(error.response)
      })
  }

  return (
    <Grid textAlign='center' style={{minHeight: '100vh', height: '100%', marginTop: 'unset'}}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>Edit FBA removal</span>
          </Header>
          <Form
            form={form}
            initialValues={data}
            name='fba_removal_edit'
            onFinish={onFinish}
            validateMessages={{}}
            scrollToFirstError
            onFieldsChange={() => {
              const currentSKUIds = (form.getFieldValue('skus') || [])
                .filter(Boolean)
                .map((sku) => sku.id)
                .filter(Boolean)
              setInvalidReceipts((data.receipts || []).some((receipt) => !currentSKUIds.includes(receipt.sku)))

              const skus = form.getFieldValue('skus')
              if (skus) {
                skus.forEach((sku, index) => {
                  if (sku && sku.quantity && sku.quantity_per_box && !form.isFieldTouched(['skus', index, 'boxes'])) {
                    form.setFields([
                      {
                        name: ['skus', index, 'boxes'],
                        value: Math.ceil(sku.quantity / sku.quantity_per_box),
                      },
                    ])
                  }
                })
              }
            }}>
            <Row gutter={40}>
              <Col span={6}>
                <Form.Item name='customer' label='Customer' rules={[{required: true}]} {...mainFormItemLayout}>
                  <Select
                    showSearch
                    style={{width: '100%'}}
                    placeholder='Select a customer'
                    options={customers.map((customer) => ({value: customer.name, label: customer.name}))}></Select>
                </Form.Item>

                <Form.Item name='warehouse' label='Warehouse' rules={[{required: true}]} {...mainFormItemLayout}>
                  <Select style={{width: '100%'}} placeholder='Select a warehouse'>
                    {warehouses.map((warehouse) => (
                      <Option key={warehouse}>{warehouse}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name='reference' label='Reference' rules={[{required: true}]} {...mainFormItemLayout}>
                  <Input />
                </Form.Item>

                <Form.Item name='status' label='Status' rules={[{required: true}]} {...mainFormItemLayout}>
                  <Select style={{width: '100%'}}>
                    <Option key='Open'>Open</Option>
                    <Option key='Complete'>Complete</Option>
                    <Option key='Canceled'>Canceled</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={18}>
                <Divider orientation='left'>SKUs</Divider>

                <Form.List name='skus'>
                  {(fields, {add, remove}) => (
                    <>
                      {fields.map((field) => (
                        <Space key={field.key} align='baseline' size='large'>
                          <Form.Item
                            {...field}
                            label='SKU'
                            name={[field.name, 'sku']}
                            fieldKey={[field.fieldKey, 'sku']}
                            rules={[{required: true}]}>
                            <Input />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            label='QTY'
                            name={[field.name, 'quantity']}
                            fieldKey={[field.fieldKey, 'quantity']}
                            rules={[{required: true, type: 'integer', min: 1}]}>
                            <InputNumber />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            label='Box Size'
                            name={[field.name, 'box_size']}
                            fieldKey={[field.fieldKey, 'box_size']}
                            rules={[{required: true}]}>
                            <Select style={{minWidth: '100px'}}>
                              {boxSizes.map((boxSize) => (
                                <Option key={boxSize}>{boxSize}</Option>
                              ))}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            {...field}
                            label='Box Weight (lbs)'
                            name={[field.name, 'box_weight']}
                            fieldKey={[field.fieldKey, 'box_weight']}
                            rules={[{required: true, type: 'number', min: 0}]}>
                            <InputNumber controls={false} />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            label='QTY Per Box'
                            name={[field.name, 'quantity_per_box']}
                            fieldKey={[field.fieldKey, 'quantity_per_box']}
                            rules={[{required: true, type: 'integer', min: 1}]}>
                            <InputNumber />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            label='Boxes'
                            name={[field.name, 'boxes']}
                            fieldKey={[field.fieldKey, 'boxes']}
                            rules={[{required: true, type: 'integer', min: 1}]}>
                            <InputNumber />
                          </Form.Item>

                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                        </Space>
                      ))}

                      <Form.Item>
                        <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
                          Add SKU
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>

                {invalidReceipts && (
                  <Form.Item>
                    <Alert type='warning' message='Receipts for the removed SKUs will also be removed.' />
                  </Form.Item>
                )}
              </Col>
            </Row>

            <Form.Item {...tailFormItemLayout}>
              <Row gutter={12}>
                <Col className='gutter-row' span={16}>
                  <Button block type='primary' htmlType='submit'>
                    Update
                  </Button>
                </Col>
                <Col className='gutter-row' span={8}>
                  <Button block type='text' onClick={() => history.push('/fba-removals')}>
                    Back
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default FBARemovalEdit
